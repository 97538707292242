<template>
  <div id="app">
    <b-row>
      <b-col>
        <p align="left">
          <b-form-group label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Pesquise aqui...">
              </b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </p>
      </b-col>

      <b-col>
        <p align="left" class="pesquisa_select">
          <b-button @click="createItem" pill variant="success" size="sm">Novo Registro</b-button>
          <!-- <b-button @click="pesquisarFuncao" pill variant="success" size="sm"> Pesquisar </b-button> -->
        </p>
      </b-col>
    </b-row>

    <b-table striped hover responsive id="my-table" :items="tableMedicos" :fields="visibleFields" :per-page="perPage"
      :current-page="currentPage" aria-controls="my-table" small>

      <template #cell(opcao)="data">

        <b-icon icon="blockquote-right" cursor="pointer" @click="addFechamentoExame(data.item)" pill variant="success"
          size="sm">
        </b-icon>
        &nbsp;
        <b-icon icon="pencil-fill" cursor="pointer" @click="editItem(data.item)" pill variant="primary" size="sm">
        </b-icon>
        &nbsp;
        <b-icon icon="trash" cursor="pointer" @click="deleteItem(data.item)" pill v-b-modal="'edit-modal'"
          variant="danger" size="sm">
        </b-icon>
      </template>

    </b-table>

    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>


    <!-- Info modal -->
    <b-modal v-model="modalShowFechamento" size="lg" hide-footer>
      <b-form @submit.prevent="saveFechamento">
        <b-col size="lg" class="alinhaFormAso fonteLinhasNgrito">
          <b-form-group id="input-group-3" label-for="input-3">
            <b-form-input id="medico_id" hidden="true" name="medico_id"
              v-model="formdataFechamento.medico_id"></b-form-input>
          </b-form-group>
          MÉDICO: {{ formdataFechamento.nome }}
        </b-col>
        <b-col md="10" class="alinhaFormAso">
          <b-form-group class="mb-12">

            <b-form-group class="mb-12" label="Exame:">
              <v-select :options="tableExame" :reduce="(option) => option.id" label="name"
                v-model="formdataFechamento.exame_id"></v-select>
            </b-form-group>

          </b-form-group>
        </b-col>

        <b-col md="2" class="alinhaFormAso">
          <b-form-group id="input-group-3" label="Valor:" label-for="input-3">
            <b-form-input id="input-2" hidden="true" v-model="formdataFechamento.editvalor"
              placeholder="editvalor"></b-form-input>
            <b-form-input id="input-3" v-model="formdataFechamento.valor" required
              :formatter="formatNumeroToReal"></b-form-input>
          </b-form-group>
        </b-col><br />

        <b-button size="sm" pill variant="danger" @click="close">
          Cancelar
        </b-button>
        &nbsp;
        <b-button type="submit" pill size="sm" variant="success">
          Salvar
        </b-button>

      </b-form>

      <b-table striped hover responsive id="my-table2" :items="listFechamentoMedicos" :fields="visibleFields2"
        :per-page="perPage2" :current-page="currentPage2" aria-controls="my-table2" small>
        <template #cell(opcao)="data">
          <b-icon icon="pencil-fill" cursor="pointer" @click="editItem2(data.item)" pill variant="primary" size="sm">
          </b-icon>
        </template>

      </b-table>

    </b-modal>

    <!-- Info modal -->
    <b-modal v-model="modalShow" hide-footer>
      <b-form @submit.prevent="save">

        <b-form-group id="input-group-2" label="Nome:" label-for="input-2">
          <b-form-input id="input-2" hidden="true" v-model="formdata.id" placeholder="id"></b-form-input>
          <b-form-input id="input-2" v-model="formdata.nome" required placeholder="Nome"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Cpf:" label-for="input-2">
          <b-form-input id="input-2" v-mask="'###.###.###-##'" v-model="formdata.cpf" required
            placeholder="Cpf"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Rg:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.rg" placeholder="Rg"></b-form-input>
        </b-form-group>


        <b-form-group id="input-group-2" label="CRM:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.crm" required placeholder="crm"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="UF CRM:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.ufcrm" required placeholder="uf"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Telefone:" label-for="input-2">
          <b-form-input id="input-2" v-mask="'(##) #####-####'" v-model="formdata.telefone" required
            placeholder="Telefone">
          </b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Data Nascimento:" label-for="input-2">
          <b-form-datepicker id="input-2" v-mask="'##/##/####'" show-decade-nav
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="formdata.datanascimento" required placeholder="Data Nascimento"></b-form-datepicker>
        </b-form-group>

        <b-form-group id="input-group-2" label="Endereço:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.endereco" placeholder="Endereço"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Email:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.email" placeholder="Email"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Ativo:" label-for="checkbox-1" size="sm" switch>
          <b-form-checkbox class="mb-3" id="checkbox-1" v-model="formdata.ativo" required
            placeholder="ativo"></b-form-checkbox>
        </b-form-group>


        <br />
        <b-button size="sm" pill variant="danger" @click="close">
          Cancelar
        </b-button>
        &nbsp;
        <b-button type="submit" pill size="sm" variant="success">
          Salvar
        </b-button>
      </b-form>
    </b-modal>


  </div>
</template>


<script>

import {http} from '@/plugins/axios'
import {  mapState, mapActions } from 'vuex'
import maskMixins from '@/helpers/mixins/mask2'
export default {

        mixins:[maskMixins], 

        data () {

          return {

            isBusy: false,
            perPage: 20,
            currentPage: 1,
            perPage2: 20,
            currentPage2: 1,
            filter: null,
            filter2: null,
            infoModal: {
                          id: 'info-modal',
                          title: '',
                          content: ''
                        },
            fields2: [
                        {
                          key: 'id',
                          label: 'id',
                          visible: false,
                          sortable: true,
                          thStyle: { width: "2%" },
                          tdClass: 'fonteLinhasLeft'
                        },
                        {
                          key: 'medico.nome',
                          label: 'Medico',
                          formatter: 'todasMaiusculas',
                          sortable: true,
                          visible: true,
                          thStyle: { width: "30%" },
                          tdClass: 'fonteLinhasLeft'
                        },  
                        {
                          key: 'exame.name',
                          label: 'Exame',
                          formatter: 'todasMaiusculas',
                          sortable: true,
                          visible: true,
                          thStyle: { width: "30%" },
                          tdClass: 'fonteLinhasLeft'
                        },
                        {
                          key: 'valor',
                          label: 'Valor',
                          sortable: true,
                          visible: true,
                          thStyle: { width: "30%" },
                          tdClass: 'fonteLinhasLeft',
                          formatter: 'convertNumberToReal'
                          },
                          {
                            key: 'opcao',
                            visible: true,
                            thStyle: { width: "7%" },
                            label: 'Opções',
                            sortable: true
                          }
                      ],
            fields: [
              {
                key: 'id',
                label: 'id',
                visible: false,
                sortable: true,
                thStyle: { width: "2%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'nome',
                label: 'Medico',
                formatter: 'todasMaiusculas',
                sortable: true,
                visible: true,
                thStyle: { width: "30%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'cpf',
                label: 'Cpf',
                formatter: 'mascaraCpf',
                sortable: true,
                visible: true,
                thStyle: { width: "15%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'crm',
                label: 'Crm',
                sortable: true,
                visible: true,
                thStyle: { width: "8%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'telefone',
                label: 'Telefone',
                sortable: true,
                visible: true,
                thStyle: { width: "15%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'ativo',
                label: 'Ativo',
                formatter: 'campoAtivo',
                sortable: true,
                visible: true,
                thStyle: { width: "7%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'datanascimento',
                label: 'Data Nascimento',
                formatter: 'formatarDataEntradaParaGrid',
                sortable: true,
                visible: true,
                thStyle: { width: "15%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'endereco',
                label: 'Endereço',
                sortable: true,
                visible: false,
                thStyle: { width: "20%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'email',
                label: 'Email',
                sortable: true,
                visible: false,
                thStyle: { width: "15%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'opcao',
                visible: true,
                thStyle: { width: "20%" },
                label: 'Opções',
                sortable: true
              }

            ],
            
            listFechamentoMedicos:[],
            formdata: {},
            modalShow: false,
            editedIndex: -1,
            formdataFechamento: {
              editvalor: '',
              medico_id:'',
              exame_id:'',
              nome:''
            },
            modalShowFechamento: false,
            editedIndexFechamento: -1

          }
        },
       
       mounted(){
          
          this.ActionFindExame(),
          this.ActionFindMedico(),
          
          setTimeout(() =>{// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                  this.isBusy = false
                  }, 2.0*2000)
        },


        computed:{
           visibleFields() {
                return this.fields.filter(field => field.visible)
              },
          
           visibleFields2() {
              return this.fields2.filter(field => field.visible)
            },

           countIndice(item){
             const index = this.tableMedicos.indexOf(item);  
             return index;
              },

           rows() {
                 return this.tableMedicos.length
                  },

          ...mapState('medico', ['tableMedicos']),
          ...mapState('exame', ['tableExame'])
        },


        methods: {

          ...mapActions('medico', ['ActionFindMedico']),
          ...mapActions('medico', ['ActionSalvar']),
          ...mapActions('medico', ['ActionEditar']),
          ...mapActions('exame', ['ActionFindExame']),
          ...mapActions('medico',['ActionSalvarFechamento']),
          ...mapActions('medico', ['ActionEditarFechamento']),


          
            async searchFechamentoMedico(idmedico) {

              if (idmedico) {
               await http.get('/medico/fechamentomedico/' + idmedico).then(resp => {
                  this.listFechamentoMedicos = resp.data
                })
              }

              
            },

          addFechamentoExame(item) {
                      console.log(item)
                  this.searchFechamentoMedico(item.id)

                  this.formdataFechamento = {
                    medico_id: item.id,
                    nome: item.nome 
              }
                       this.modalShowFechamento = true;
                       this.editedIndexFechamento = -1;
                    },

            createItem() {

                        this.modalShow = true;
                        this.formdata = {}
                        this.editedIndex = -1;
                                           
            },

            editItem(item) {

                    
                       this.modalShow = true;
                       this.editedIndex = this.tableMedicos.indexOf(item);
                       this.formdata = Object.assign({}, item);
                           },


          editItem2(item) {

          
            this.editedIndex = this.tableMedicos.indexOf(item);
            this.formdataFechamento = Object.assign({}, item);
            this.formdataFechamento.editvalor = 'em_edicao'


          },



              close() {
                     this.modalShow = false;

                     setTimeout(() => {
                          this.formdata = {}
                          this.editedIndex = -1;
                          this.ActionFindMedico()
                              }, 300);
                     },

   async saveFechamento() {

      try {

        this.formdataFechamento.valor = this.converteMoedaFloat(this.formdataFechamento.valor);
        delete this.formdataFechamento.nome



         if (!this.formdataFechamento.id){//entra para salvar

          await this.ActionSalvarFechamento(this.formdataFechamento)
        
          alert('Cadastrado com sucesso!')
          
        }

        else {//entra para editar
           delete this.formdataFechamento.medico
           delete this.formdataFechamento.editvalor
           delete this.formdataFechamento.exame
           await this.ActionEditarFechamento(this.formdataFechamento)
           this.formdataFechamento.exame_id = {}
           this.formdataFechamento.editvalor = ''
         
          alert('Atualizado com sucesso!')
        }

        this.formdataFechamento.valor = 0;

        this.searchFechamentoMedico(this.formdataFechamento.medico_id)
      } catch (err) {
        alert(err.data ? err.data.message : 'Não foi possível salvar')
      }
    },

     async save() {

            try {
                   this.formdata.datanascimento = this.formatarDataParaBancoTmz(this.formdata.datanascimento);
                        
                        if(!this.formdata.id){//entra para salvar

                                await this.ActionSalvar(this.formdata)
                                this.ActionFindMedico()
                                alert('Cadastrado com sucesso!')
                            }

                            else{//entra para editar
                                  await this.ActionEditar(this.formdata)
                                  this.ActionFindMedico()
                                  alert('Atualizado com sucesso!')
                                  this.tableMedicos = []
                            }

                            this.formdata = {} 

                      } catch (err) {
                          alert(err.data ? err.data.message : 'Não foi possível salvar')
                        }
                },  
              
           async deleteItem(data){

                            const index = this.tableMedicos.indexOf(data);  
                
                            if(confirm('Deseja excluir o item?') && this.tableMedicos.splice(index, 1) ){
                                await this.ActionDeletar(data.id)
                                this.editedItem = Object.assign({}, data); 
                            }
                          },
             mascaraCnpj(value) {
                            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                            return mascara;
                  },

             formatarData: function (value) {
                       if (value === null) { return null }
                      return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit', minute:'2-digit', second:'2-digit' })
                  },
                  
              resetInfoModal() {
                         this.infoModal.title = ''
                         this.infoModal.content = ''
                  }

          }
  }
</script>
<style >
.fonteLinhas {
   font-size:14px;
   text-align: center
}
.fonteLinhasNgrito {
   font-size:14px;
   text-align: center;
  font-weight: bold;
}
.fonteLinhasLeft {
   font-size:14px;

}
.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}
.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select{

  position: relative;
  margin-top: 20px;

}


.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}

</style>